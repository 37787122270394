import styled, { css } from 'styled-components/macro';
import tokens from '../../Tokens/tokens';
import { getBreakpoint, getSpacing, pxToRem } from '../../utils';

const { font, colors } = tokens;
const { primaryButton, secondaryButton, tertiaryButton, linkButton, disabled } = colors.buttons;

export const genericButtonStyles = css`
    font-family: 'Gotham', Arial, sans-serif;
    height: auto;
    width: 100%;
    padding: calc((48px - 4px - 1.5rem) / 2) ${getSpacing('md')};
    line-height: ${font.lineHeight.default};
    font-size: 1rem;
    font-weight: ${font.weight.medium};
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    cursor: pointer;
    border: 0;
    transition: border-color 0.5s ease, background-color 0.5s ease,
        color 0.5s ease;
`;

const StyledButton = styled.button`
    ${genericButtonStyles}

    &:focus {
        outline: 2px solid ${colors.borders.focus};
        outline-offset: 2px;
    }

    ${getBreakpoint.sm`
        width: auto;
        min-width: ${pxToRem(200)};
    `}

    ${props => (props.variant || props.theme) && (themeVariant[(props.variant || props.theme)])};
    ${props => props.size && sizeVariant[props.size]};

    ${props => props.disabled && css`
        ${disabledStyle}
    `};

    ${props => props.external && css`
        ${externalStyle}
    `};

    ${props =>
        props.external && (
            ((props.variant || props.theme) === 'tertiary') ||
            ((props.variant || props.theme) === 'link') ||
            ((props.variant || props.theme) === 'back') ||
            ((props.variant || props.theme) === 'arrow')
        ) && css`
        ${externalStyle}
        text-decoration: underline;
    `};

    svg {
        width: ${pxToRem(12)};
        height: ${pxToRem(12)};
    }

    &:hover:not(:disabled) {
        svg {
            fill: ${colors.white};
        }
    }

    &:active {
        svg {
            fill: ${colors.white};
        }
    }
`;

const primaryButtonStyle = css`
    color: ${colors.white};
    background-color: ${primaryButton.background};
    border: 2px solid ${primaryButton.background};

    &:hover {
        color: ${colors.white};
        background-color: ${primaryButton.backgroundHover};
        border-color: transparent;
    }

    &:active {
        color: ${colors.white};
        background-color: ${primaryButton.background};
        border-color: transparent;
        opacity: 80%;
    }

    &:disabled {
        color: ${colors.text.disabled};
        background-color: ${disabled.background};
        border: 2px solid ${disabled.background};
        svg {
           fill: ${colors.text.disabled};
        }
    }

    ${getBreakpoint.print`
        border: 2px solid ${primaryButton.background};
        background: ${colors.white};
        color: ${primaryButton.background};
    `}
`;

export const secondaryButtonStyle = css`
    color: ${secondaryButton.text};
    background-color: ${secondaryButton.background};
    border: 2px solid ${secondaryButton.border};

    &:hover {
        color: ${colors.white};
        background-color: ${secondaryButton.backgroundHover};
        border: 2px solid ${secondaryButton.backgroundHover};
    }

    &:active {
        color: ${colors.white};
        background-color: ${secondaryButton.backgroundActive};
        border: 2px solid ${secondaryButton.border};
    }

    &:disabled {
        color: ${colors.text.disabled};
        background-color: ${disabled.background};
        border: 2px solid ${disabled.background};
        svg {
           fill: ${colors.text.disabled};
        }
    }

    ${getBreakpoint.print`
        border: 2px solid ${secondaryButton.border};
        background: ${colors.white};
        color: ${secondaryButton.background};
    `}
`;

const tertiaryButtonStyle = css`
    color: ${tertiaryButton.text};
    border: 2px solid transparent;
    background: none;
    text-decoration: underline;

    &:hover {
        color: ${colors.white};
        background-color: ${tertiaryButton.backgroundHover};
    }

    &:active {
        color: ${colors.white};
        background-color: ${tertiaryButton.backgroundActive};
    }

    &:disabled {
        color: ${colors.text.disabled};
        background: none;
        border: none;
        svg {
           fill: ${colors.text.disabled};
        }
    }

    ${getBreakpoint.print`
        color: ${tertiaryButton.text};
    `}
`;

const whiteButtonStyle = css`
    color: #242934;
    background-color: ${colors.white};
    border: 2px solid #c4d0d6;

    &:hover {
        color: #242934;
        background-color: ${colors.white};
        border-color: #deeaf0;
    }

    &:active {
        color: #242934;
        background-color: ${colors.white};
        border-color: #929fa4;
    }

    &:disabled {
        color: #242934;
        background-color: ${colors.white};
        border: 2px solid #c4d0d6;
    }

    ${getBreakpoint.print`
        border: 2px solid #929fa4;
        background: ${colors.white};
        color: ${tertiaryButton.text};
    `}
`;

const linkButtonStyle = css`
    text-decoration: underline;
    padding: 0 2px;
    text-align: left;
    min-width: 0;
    background: none;
    color: ${linkButton.text};
    border-radius: 0;
    transition: fill 0.5s ease;

    ${getBreakpoint.sm`
        width: auto;
        min-width: 0;
    `}

    svg {
        fill: ${linkButton.text};
    }

    &:hover:not(:disabled) {
        color: ${colors.white};
        background-color: ${linkButton.backgroundHover};
        svg {
            fill: ${colors.white};
        }
    }

    &:disabled {
        color: ${colors.text.disabled};
        background: none;
        border: none;
        svg {
           fill: ${colors.text.disabled};
        }
    }

    &:active {
        color: ${colors.white};
        background-color: ${linkButton.backgroundActive};
    }
`;

const backStyle = css`
    ${linkButtonStyle};

    svg {
        margin-right: ${pxToRem(4)};
    }
`;

const arrowStyle = css`
    ${linkButtonStyle};

    svg {
        margin-left: ${pxToRem(8)};
    }
`;

const externalStyle = css`
    text-decoration: none;
    transition: fill 0.5s ease;

    svg {
        margin-left: ${pxToRem(4)};
        ${props => ((props.variant || props.theme) && themeIconVariant[(props.variant || props.theme)])};
    }
`;

const disabledStyle = css`
    cursor: not-allowed;
    -webkit-transition: none;
    transition: none;
`;

const smallButtonSize = css`
    font-size: 0.875rem;
    line-height: 1em;
    padding-top: calc((36px - 4px - 1em) / 2);
    padding-bottom: calc((36px - 4px - 1em) / 2);
`;

const xlButtonSize = css`
    padding-top: calc((48px - 4px - 1.5rem) / 2);
    padding-bottom: calc((48px - 4px - 1.5rem) / 2);

    ${getBreakpoint.md`
        font-size: 1.125rem;
        padding-top: calc((56px - 4px - 1.5rem) / 2);
        padding-bottom: calc((56px - 4px - 1.5rem) / 2)
    `}
`;

const xxlButtonSize = css`
    padding-top: calc((48px - 4px - 1.5rem) / 2);
    padding-bottom: calc((48px - 4px - 1.5rem) / 2);

    ${getBreakpoint.md`
        font-size: 1.375rem;
        line-height: 2rem;
        padding-top: calc((80px - 4px - 2rem) / 2);
        padding-bottom: calc((80px - 4px - 2rem) / 2);
        border-radius: 10px;
    `}
`;

const shortButtonSize = css`
    width: auto;
    min-width: 0;
    padding-left: ${getSpacing('md')};
    padding-right: ${getSpacing('md')};

    ${getBreakpoint.sm`
        width: auto;
        min-width: 0;
    `}
`;

const primaryIconStyle = css`
    fill: ${colors.white};
`;

const secondaryIconStyle = css`
    fill: ${secondaryButton.text};
`;

const tertiaryIconStyle = css`
    fill: ${tertiaryButton.text};
`;

const whiteIconStyle = css`
    fill: #242934;
`;

const linkIconStyle = css`
    fill: ${linkButton.text};
`;

const themeVariant = {
    primary: primaryButtonStyle,
    secondary: secondaryButtonStyle,
    tertiary: tertiaryButtonStyle,
    white: whiteButtonStyle,
    link: linkButtonStyle,
    arrow: arrowStyle,
    back: backStyle,
};

const themeIconVariant = {
    primary: primaryIconStyle,
    secondary: secondaryIconStyle,
    tertiary: tertiaryIconStyle,
    white: whiteIconStyle,
    link: linkIconStyle,
};

const sizeVariant = {
    small: smallButtonSize,
    xl: xlButtonSize,
    xxl: xxlButtonSize,
    short: shortButtonSize,
};

export default StyledButton;
