import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';
import AutoSuggest from '../../Components/AutoSuggest/AutoSuggest';
import ManualAddress from '../ManualAddress/ManualAddress';
import { Button, Fieldset, FormGroup, IconChevronLeft, useNSWPointV2API } from '../..';
import { isEmptyObj, useMountedState, debounce } from '../../utils';
import { getErrorMessage, getHasError, getValue } from '../utils';
import { StyledAutoSuggestAddress } from './AutoSuggestAddress.styled.js';

const currModes = {
    AUTO: 'auto',
    MANUAL: 'manual',
    INITIAL: 'initial'
};
const focusOnReturnOption = {
    INPUT: 'input',
    BUTTON: 'button',
};

const AutoSuggestAddress = ({
    id,
    label,
    helpMessage,
    placeholder,
    value,
    onSelect,
    onChange,
    onBlur,
    onSwitch,
    apiOptions,
    initialMode,
    persistentAddress,
    backendAPI: customBackendAPI
}) => {
    /**
     * alpha todo list:
     * - refactor api/nswPointV2Api to ensure reusable given switch to axios
     * - add tests for api/nswPointV2Api
     * - add tests for api (if we revise/rebuild it in axios)
     * - mark old Forms/Address component as obsolete
     */

    // states
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [currMode, setCurrMode] = useState(initialMode === 'manual' ? initialMode : currModes.INITIAL);
    const [addressDetails, setAddressDetails] = useState({});
    const [suggestionId, setSuggestionId] = useState(null);
    const [autoSuggestInputValue, setAutoSuggestInputValue] = useState('');

    // hooks
    const maxResults = 6;
    const backendApi = useNSWPointV2API({
        ...apiOptions,
        customBackendAPI,
        maxResults
    });
    const isMounted = useMountedState();

    // refs
    const formattedAddressRef = useRef(null);
    const streetAddressRef = useRef(null);
    const manualButtonRef = useRef(null);
    const focusOnReturnRef = useRef(null);
    const manualAddressRef = useRef(null);

    // vars
    const appendedSuggestion = {
        // eslint-disable-next-line max-len
        suggestion: <div>Unable to match an address? <span className='switch-to-manual-mode-cta'>Enter manually</span></div>,
        onClick: () => switchToManualMode(focusOnReturnOption.INPUT)
    };
    const defaultManualFields = {
        suburb: { ...value.suburb, value: undefined },
        state: { ...value.state, value: undefined },
        postcode: { ...value.postcode, value: undefined },
        streetAddress: { ...value.streetAddress, value: undefined },
        country: { ...value.country, value: 'Australia' },
    };
    const defaultAutoFields = {
        nswPointId: { ...value.nswPointId, value: undefined },
        formattedAddress: { ...value.formattedAddress, value: undefined },
        unitNumber: { ...value.unitNumber, value: undefined },
        buildingNumber: { ...value.buildingNumber, value: undefined },
        streetNumber: { ...value.streetNumber, value: undefined },
        streetName: { ...value.streetName, value: undefined },
        streetType: { ...value.streetType, value: undefined },
        propertyName: { ...value.propertyName, value: undefined },
        latitude: { ...value.latitude, value: undefined },
        longitude: { ...value.longitude, value: undefined },
        postalDeliveryType: { ...value.postalDeliveryType, value: undefined },
        postalDeliveryNumber: { ...value.postalDeliveryNumber, value: undefined },
        lgaName: { ...value.lgaName, value: undefined },
        lgaShortName: { ...value.lgaShortName, value: undefined },
        lgaPid: { ...value.lgaPid, value: undefined },
        cadastralIdentifier: { ...value.cadastralIdentifier, value: undefined },
        cadastralParcels: { ...value.cadastralParcels, value: undefined },
        validated: { value: false },
    };
    const defaultAddressFields = {
        ...defaultManualFields,
        ...defaultAutoFields,
    };
    const persistentAddressAuto = ['auto', 'both'].includes(persistentAddress);
    const persistentAddressManual = ['manual', 'both'].includes(persistentAddress);

    useEffect(() => {
        trackMe('PatternAutoSuggestAddress [GEL]');
    }, []);

    // get suggestions from NSW Point API, when input value changes
    useEffect(() => {
        if (autoSuggestInputValue) {
            const fetchSuggestions = async () => {
                const response = await backendApi.getLatestSuggestions(autoSuggestInputValue);
                const { suggestions, isLatest } = response;
                if (isLatest) {
                    isMounted && setFilteredSuggestions(suggestions);
                }
            };
            fetchSuggestions();
        } else {
            setFilteredSuggestions([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSuggestInputValue]);

    // fetch the address details from NSW Point API, when suggestion ID changes
    useEffect(() => {
        if (suggestionId) {
            const fetchAddressDetails = async () => {
                const address = await backendApi.getDetailsById(suggestionId);
                isMounted && setAddressDetails(address);
            };
            fetchAddressDetails();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestionId]);

    // call onSelect with the address details from NSW Point API
    useEffect(() => {
        if (!isEmptyObj(addressDetails)) {
            const mergedAddress = mergeAddressWithApiResponse(value, addressDetails);
            onSelect && onSelect(mergedAddress, 'formattedAddress', isComplete(mergedAddress));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressDetails]);

    // manage input focus when switching modes
    useEffect(() => {
        // don't focus on load - only when switching modes
        if (currMode !== currModes.INITIAL) {
            if (currMode === currModes.AUTO) {
                if (focusOnReturnRef.current === focusOnReturnOption.BUTTON) {
                    manualButtonRef.current.focus();
                } else {
                    formattedAddressRef.current.focus();
                }
            } else {
                manualAddressRef.current.querySelector('input[name="streetAddress"]').focus();
            }
        }
    }, [currMode]);

    const switchToAutoMode = () => {
        setCurrMode(currModes.AUTO);
        // if not retaining the address values, ping onChange with defaults
        !persistentAddressAuto && onChange && onChange(defaultAddressFields, false, false);
        // ping callback
        onSwitch && onSwitch(currModes.AUTO);
    };

    const switchToManualMode = focusOnReturn => {
        // if "Enter address manually" button is clicked
        focusOnReturnRef.current = focusOnReturn;
        setCurrMode(currModes.MANUAL);
        // if not retaining the address values
        if (!persistentAddressManual) {
            // clear any filtered suggestions
            setFilteredSuggestions([]);
            // clear the auto input value
            setAutoSuggestInputValue('');
            // ping onChange with injected input ref and default values
            onChange && onChange(injectStreetAddressRef(defaultAddressFields), false, false);
        } else {
            // ping onChange with injected input ref and existing values
            onChange && onChange(injectStreetAddressRef(value), false, false);
        }
        // ping callback
        onSwitch && onSwitch(currModes.MANUAL);
    };

    // add the streetAddress input ref to aid with focus
    const injectStreetAddressRef = address => {
        const updatedAddress = { ...address };
        updatedAddress?.streetAddress ?
            updatedAddress.streetAddress.inputRef = streetAddressRef :
            updatedAddress.streetAddress = { inputRef: streetAddressRef };
        return updatedAddress;
    };

    // helper to determine whether all essential address fields have been filled
    const isComplete = address => (
        currMode !== currModes.MANUAL ?
            !!address?.validated?.value :
            Object.keys(defaultManualFields).every(key => getValue(key, address) !== '')
    );

    // helper to merge any value data passed in with the boilerplate/default structure
    const mergeAddressWithBoilerplate = (boilerplateAddress, newAddress) => {
        const updatedAddress = { ...newAddress };
        Object.entries(boilerplateAddress).forEach(([ key, val ]) => {
            key in updatedAddress ?
                updatedAddress[`${key}`] = { ...updatedAddress[`${key}`] } :
                updatedAddress[`${key}`] = { ...boilerplateAddress[`${key}`] };
        });
        return updatedAddress;
    };

    // helper to merge the API response with the existing value shape
    const mergeAddressWithApiResponse = (existingAddress, newAddress) => {
        const updatedAddress = { ...existingAddress };
        Object.entries(newAddress).forEach(([ key, val ]) => {
            updatedAddress[`${key}`] = (key in updatedAddress) ?
                { ...updatedAddress[`${key}`], value: val } :
                { value: val };
        });
        return updatedAddress;
    };

    const handleOnChangeAuto = (suggestion, inputValue, method) => {
        // fetch address suggestions if min chars entered
        if (inputValue.trim().length >= 4) {
            // only update the input value state if the user is typing, to initiate the API call
            method === 'type' && debounce(setAutoSuggestInputValue(inputValue), 500);
        } else {
            setAutoSuggestInputValue('');
        }

        // ensure we have a complete value object structure
        const updatedAddress = mergeAddressWithBoilerplate(defaultAddressFields, value);

        // if user has typed, set address to invalid
        if (method === 'type') {
            updatedAddress.validated ?
                updatedAddress.validated.value = false :
                updatedAddress.validated = { value: false };
        }

        // update formattedAddress with whatever the user has entered
        updatedAddress.formattedAddress ?
            updatedAddress.formattedAddress.value = inputValue :
            updatedAddress.formattedAddress = { value: inputValue };

        onChange && onChange(updatedAddress, 'formattedAddress', isComplete(updatedAddress));
    };

    // set the selected suggestion ID in state
    const handleOnSelectAuto = (suggestion, inputValue, method) => {
        if (suggestion?.id) {
            setSuggestionId(suggestion.id);
        }
    };

    const handleOnBlurAuto = (suggestion, inputValue, highlightedValue) => {
        const updatedAddress = mergeAddressWithBoilerplate(defaultAddressFields, value);
        onBlur && onBlur(
            updatedAddress,
            'formattedAddress',
            isComplete(updatedAddress),
            inputValue,
            highlightedValue.address || ''
        );
    };

    const handleOnChangeManual = (address, key) => {
        let updatedAddress = mergeAddressWithBoilerplate(defaultAddressFields, address);
        let addressIsComplete = isComplete(updatedAddress);

        // if the address is persistent and complete
        if (persistentAddressManual && addressIsComplete) {
            // recreate address without any of the API data
            // update formattedAddress with current values entered by user
            updatedAddress = mergeAddressWithBoilerplate(defaultAddressFields, {
                formattedAddress: {
                    // eslint-disable-next-line max-len
                    value: `${updatedAddress.streetAddress.value}, ${updatedAddress.suburb.value} ${updatedAddress.state.value} ${updatedAddress.postcode.value}`
                },
                streetAddress: { ...address.streetAddress },
                suburb: { ...address.suburb },
                state: { ...address.state },
                postcode: { ...address.postcode },
            });
            addressIsComplete = isComplete(updatedAddress);
            // clear any filtered suggestions
            setFilteredSuggestions([]);
        }

        onChange && onChange(updatedAddress, key, addressIsComplete);
    };

    const handleOnBlurManual = (address, key) => {
        const updatedAddress = mergeAddressWithBoilerplate(defaultAddressFields, address);
        onBlur && onBlur(updatedAddress, key, isComplete(updatedAddress), address[`${key}`].value, '');
    };

    return (<StyledAutoSuggestAddress>
        {currMode !== currModes.MANUAL ?
            <>
                <FormGroup
                    id={ `${id}-auto-suggest-formgroup` }
                    label={ label }
                    helpMessage={ helpMessage ? helpMessage : null }
                    errorMessage={ currMode !== currModes.MANUAL && getErrorMessage('formattedAddress', value) }
                    hasError={ currMode !== currModes.MANUAL && getHasError('formattedAddress', value) }
                >
                    <AutoSuggest
                        id={ `${id}-autosuggest` }
                        placeholder={ placeholder }
                        inputRef={ formattedAddressRef }
                        onSelect={ handleOnSelectAuto }
                        onChange={ handleOnChangeAuto }
                        onBlur={ handleOnBlurAuto }
                        suggestions={ filteredSuggestions }
                        appendedSuggestion={ appendedSuggestion }
                        filterCustomSuggestions={ () => filteredSuggestions }
                        renderCustomSuggestion={ suggestion => suggestion.address }
                        getCustomSuggestion={ suggestion => suggestion.address }
                        value={ getValue('formattedAddress', value) }
                        minSearchChars={ 4 }
                    />

                </FormGroup>
                <Button
                    className='switch-to-manual-mode-cta'
                    ref={ manualButtonRef }
                    onClick={ () => switchToManualMode(focusOnReturnOption.BUTTON) }
                    variant='link'
                >
                    Enter address manually
                </Button>
            </> :
            <Fieldset
                legend={ label }
                smallLegend
            >
                <ManualAddress
                    id={ `${id}-manual-address` }
                    value={ value }
                    onChange={ handleOnChangeManual }
                    onBlur={ handleOnBlurManual }
                    statesOverride={ apiOptions.stateTerritory || null }
                    ref={ manualAddressRef }
                />
                <Button
                    className='switch-to-auto-mode-cta'
                    onClick={ () => switchToAutoMode() }
                    variant='link'
                >
                    <IconChevronLeft />
                    Back to search
                </Button>
            </Fieldset>}
    </StyledAutoSuggestAddress>);
};

AutoSuggestAddress.propTypes = {
    /** Unique ID for the container, also used as a prefix for the form input fields. */
    id: PropTypes.string.isRequired,
    /** Label applied to pattern. */
    label: PropTypes.string.isRequired,
    /** Help message displayed below the label. */
    helpMessage: PropTypes.string,
    /** Control input data, including id, value, error message and input ref. */
    value: PropTypes.shape({
        country: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.oneOf(['Australia', 'AUSTRALIA', '']),
            errorMessage: PropTypes.string,
        }),
        streetAddress: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
        }),
        postcode: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
        }),
        suburb: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
        }),
        state: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
        }),
        formattedAddress: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
        }),
    }).isRequired,
    /** Callback returning all address fields, key of updated field, boolean for complete address. */
    onSelect: PropTypes.func,
    /** Callback returning all address fields, key of updated field, boolean for complete address. */
    onChange: PropTypes.func.isRequired,
    // eslint-disable-next-line max-len
    /** Callback returning all address fields, key of updated field, boolean for complete address, current input value, current highlighted value. */
    onBlur: PropTypes.func,
    /** Callback returning either 'auto' or 'manual' depending on current mode. */
    onSwitch: PropTypes.func,
    // eslint-disable-next-line max-len
    /** Configure the API according to your requirements, including key, restricting by state/territory, address type, and additional fields. */
    apiOptions: PropTypes.shape({
        // NOTE: any changes to the apiOptions need to be replicated in AutoSuggestAddress.stories.js
        // so they display in Storybook correctly.
        key: PropTypes.string,
        stateTerritory: PropTypes.arrayOf(PropTypes.oneOf(['NSW', 'QLD', 'NT', 'WA', 'SA', 'VIC', 'ACT', 'TAS'])),
        addressType: PropTypes.oneOf(['all', 'mailing', 'physical']),
        outFields: PropTypes.arrayOf(PropTypes.oneOf(['cadastralParcels', 'lgaName', 'lgaPid', 'lgaShortName'])),
        // dataSet: PropTypes.arrayOf(PropTypes.oneOf(['all', 'gnaf', 'gnaflive', 'mailAddress'])),
    }),
    /** Override default API calls with your own calls. */
    backendAPI: PropTypes.shape({
        getSuggestions: PropTypes.func,
        getDetailsById: PropTypes.func
    }),
    /** Optionally initialise in either auto or manual mode. */
    initialMode: PropTypes.oneOf(['auto', 'manual']),
    /** Retain data when switching to specific mode(s). */
    persistentAddress: PropTypes.oneOf(['auto', 'manual', 'both'])
};

// TODO: fill in rest of default props.
AutoSuggestAddress.defaultProps = {
    value: {},
    apiOptions: {
        key: null,
        addressType: 'all'
    },
    backendAPI: {
        getSuggestions: Function.prototype,
        getDetailsById: Function.prototype
    }
};

export default AutoSuggestAddress;
