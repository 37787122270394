import styled from 'styled-components/macro';
import { pxToRem } from '../../utils';

export const LinkContainer = styled.a`
  text-decoration: underline;

  svg {
    width: ${pxToRem(12)};
    height: ${pxToRem(12)};
    margin-left: ${pxToRem(4)};
  }
`;
