import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from '../..';
import { getId, getValue, getErrorMessage, getHasError } from '../utils';

const InternationalAddressForm = ({
    id,
    value,
    onChange,
    isObsoleteValue,
    getObsoleteValue
}) => (<>
    <FormGroup
        id={ `${id}-form-group-address1` }
        label='Address line 1'
        helpMessage='Street address, P.O. box, company name, c/o'
        errorMessage={ getErrorMessage('address1', value) }
        hasError={ getHasError('address1', value) }
    >
        <Input
            id={ getId('address1', value, `${id}-address1`) }
            name='address1'
            value={ (isObsoleteValue && getObsoleteValue) ?
                getObsoleteValue('address1', value) :
                getValue('address1', value) }
            onChange={ e => onChange && onChange(e.target.name, e.target.value) }
            aria-required={ true }
        />
    </FormGroup>

    <FormGroup
        id={ `${id}-form-group-address2` }
        label='Address line 2 (optional)'
        helpMessage='Apartment, suite, unit, floor, etc'
    >
        <Input
            id={ getId('address2', value, `${id}-address2`) }
            name='address2'
            value={ (isObsoleteValue && getObsoleteValue) ?
                getObsoleteValue('address2', value) :
                getValue('address2', value) }
            onChange={ e => onChange && onChange(e.target.name, e.target.value) }
        />
    </FormGroup>

    <FormGroup id={ `${id}-form-group-city` } label='City/town (optional)'>
        <Input
            id={ getId('city', value, `${id}-city`) }
            name='city'
            value={ (isObsoleteValue && getObsoleteValue) ?
                getObsoleteValue('city', value) :
                getValue('city', value) }
            onChange={ e => onChange && onChange(e.target.name, e.target.value) }
        />
    </FormGroup>

    <FormGroup id={ `${id}-form-group-state` } label='State/province/region (optional)'>
        <Input
            id={ getId('state', value, `${id}-state`) }
            name='state'
            value={ (isObsoleteValue && getObsoleteValue) ?
                getObsoleteValue('state', value) :
                getValue('state', value) }
            onChange={ e => onChange && onChange(e.target.name, e.target.value) }
        />
    </FormGroup>

    <FormGroup id={ `${id}-form-group-postcode` } label='Postal/zip code (optional)'>
        <Input
            id={ getId('postcode', value, `${id}-postcode`) }
            name='postcode'
            value={ (isObsoleteValue && getObsoleteValue) ?
                getObsoleteValue('postcode', value) :
                getValue('postcode', value) }
            onChange={ e => onChange && onChange(e.target.name, e.target.value) }
            inputWidth='lg'
        />
    </FormGroup>
</>);

InternationalAddressForm.propTypes = {
    /** Unique ID for the container, also used as a prefix for the form input fields. */
    id: PropTypes.string.isRequired,
    /** Current input field values. */
    value: PropTypes.object.isRequired,
    /** Used to handle current state of all input fields. */
    onChange: PropTypes.func.isRequired
};

export default InternationalAddressForm;
