/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';
import Button from '../../Components/Button/Button';
import Input from '../../Components/Input/Input';
import Field from '../../Components/Form/Field.styled';
import Heading from '../../Components/Headings/Heading';
import { IconDownload, IconEmail, IconPrint } from '../../Icons/system';
import StyledKeepARecord from './KeepARecord.styled';

export const KeepARecord = props => {
    const {
        id,
        className,
        title,
        headingLevel,
        fileName,
        fileSize,
        fileType,
        onClickDownload,
        onClickPrint,
        onClickEmail,
        hasError,
        errorMessage,
        successMessage,
        emailButton,
        email,
        downloadStatus,
        printStatus,
        emailStatus,
        ...inputProps
    } = props;

    useEffect(() => {
        trackMe('PatternKeepARecord [GEL]');
    }, []);

    const idError = `${id}-error`;
    const idSuccess = `${id}-success`;
    const showEmailError = (hasError && !!errorMessage) || (emailStatus === 'error' && !!errorMessage);
    const showEmailSuccess = emailStatus === 'success' && !!email;
    const formatFileDetails = (`${fileType} ${fileSize}`).toUpperCase();
    const formatFileType = (`${fileType}`).toUpperCase();
    const iconColor = emailButton === 'primary' ? 'white' : 'secondaryBlue';

    return (
        <StyledKeepARecord className={ className } id={ `${id}-keep-a-record` }>
            <Heading level={ headingLevel }>{title}</Heading>
            <StyledKeepARecord.Group>
                <StyledKeepARecord.IconButtonLinkWrapper>
                    <IconDownload />
                    <Button
                        id={ `${id}-download` }
                        variant='link'
                        onClick={ onClickDownload }
                    >
                        {`Download ${fileName} (${formatFileDetails})`}
                    </Button>
                </StyledKeepARecord.IconButtonLinkWrapper>
                {downloadStatus === 'error' ?
                    <StyledKeepARecord.Error id={ `${id}-download-error` }>
                        {`We can't download your ${formatFileType} because of a technical problem. Please wait a few minutes and try again.`}
                    </StyledKeepARecord.Error> : null}
                {downloadStatus === 'loading' ?
                    <StyledKeepARecord.Loading>{`Downloading ${fileName} …`}</StyledKeepARecord.Loading> : null}
            </StyledKeepARecord.Group>
            <StyledKeepARecord.Group>
                <StyledKeepARecord.IconButtonLinkWrapper>
                    <IconPrint />
                    <Button
                        id={ `${id}-print` }
                        variant='link'
                        onClick={ onClickPrint }
                    >
                        {`Print ${fileName}`}
                    </Button>
                </StyledKeepARecord.IconButtonLinkWrapper>
                {printStatus === 'error' ? <StyledKeepARecord.Error id={ `${id}-print-error` }>
                    {`We can't print your ${formatFileType} because of a technical problem. Please wait a few minutes and try again.`}
                </StyledKeepARecord.Error> : null }
                {printStatus === 'loading' ?
                    <StyledKeepARecord.Loading>{`Printing ${fileName} …`}</StyledKeepARecord.Loading> : null}
            </StyledKeepARecord.Group>
            {onClickEmail &&
                <StyledKeepARecord.Group>
                    <Field.Label htmlFor={ `${id}-email-input` }>
                        <StyledKeepARecord.IconButtonLinkWrapper>
                            <IconEmail />
                            {`Email ${fileName}`}
                        </StyledKeepARecord.IconButtonLinkWrapper>
                    </Field.Label>
                    <Input
                        id={ `${id}-email-input` }
                        name='email'
                        inputWidth='xl'
                        aria-required={ false }
                        aria-describedby={ idError }
                        hasError={ showEmailError }
                        { ...inputProps }
                    />
                    {showEmailError ?
                        <Field.Error id={ idError }>{errorMessage}</Field.Error> : null}
                    {showEmailSuccess ?
                        <StyledKeepARecord.EmailSuccess id={ idSuccess }>
                            {`Your ${fileName} has been sent to `}<strong>{`${email}`}</strong>.
                            {successMessage && ' It may take a few minutes for the file to arrive in your inbox.'}
                        </StyledKeepARecord.EmailSuccess> : null}
                    {emailStatus === 'loading' ?
                        <StyledKeepARecord.EmailLoading>Sending email …</StyledKeepARecord.EmailLoading> : null}
                    <StyledKeepARecord.IconButton
                        id={ `${id}-email` }
                        theme={ emailButton }
                        onClick={ onClickEmail }
                    >
                        <IconEmail color={ iconColor } />
                        Send
                    </StyledKeepARecord.IconButton>
                </StyledKeepARecord.Group>
            }
        </StyledKeepARecord>
    );
};

KeepARecord.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    headingLevel: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([ 2, 3, 4, 5, 6 ]),
    ]),
    emailButton: PropTypes.oneOf(['primary', 'secondary']),
    id: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    fileSize: PropTypes.string.isRequired,
    fileType: PropTypes.string,
    onClickDownload: PropTypes.func.isRequired,
    onClickPrint: PropTypes.func.isRequired,
    onClickEmail: PropTypes.func,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    email: PropTypes.string,
    successMessage: PropTypes.bool,
    downloadStatus: PropTypes.oneOf(['idle', 'loading', 'error']),
    printStatus: PropTypes.oneOf(['idle', 'loading', 'error']),
    emailStatus: PropTypes.oneOf(['idle', 'loading', 'error', 'success']),
};

KeepARecord.defaultProps = {
    className: '',
    title: 'Keep a record',
    headingLevel: 2,
    fileType: 'pdf',
    hasError: false,
    errorMessage: '',
    onClickEmail: null,
    emailButton: 'secondary',
    email: '',
    successMessage: false,
    downloadStatus: 'idle',
    printStatus: 'idle',
    emailStatus: 'idle',
};

export default KeepARecord;
