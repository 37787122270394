import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import tokens from '../../Tokens/tokens';
import { pxToRem, getSpacing, getBreakpoint } from '../../utils';

const { colors, font } = tokens;

const StyledInPageAlert = styled.div.attrs(
    props => ({
        className: classNames(props.className)
    })
)`
    ${props =>
        props.compact && css`
        padding: ${getSpacing('sm')} !important;
    `};

    line-height: ${font.lineHeight.default};
    border-left: 6px solid ${colors.borders.info};
    padding: ${getSpacing(['sm', 'sm', 'sm', 12])};
    margin: ${getSpacing(['sm', 'none'])};


    a {
        color: ${colors.text.body};
        cursor: pointer;
        text-decoration: underline;
        svg {
            fill: ${colors.text.body};
        }
    }


    h2 {
        font-size: ${pxToRem(font.size.base)};
        line-height: ${font.lineHeight.default};
        font-weight: ${font.weight.bold};
    }

    h2, li, ol, p, ul {
        margin: ${getSpacing(['none', 'none', 8])};
        max-width: 100%;
    }

    li:last-child,
    ol:last-child,
    p:last-child,
    ul:last-child {
        margin-bottom: 0;
    }

    ${props => props.variant && InPageAlertStyle};

    &:focus {
        outline: 2px solid ${colors.borders.focus};
        outline-offset: 2px;
    }

    ${getBreakpoint.md`
        padding: ${getSpacing(['md', 'md', 'md', 'sm'])};
        ${props => !props.variant && `
            padding: ${getSpacing(['md', 'md', 'md', 54])};
        `}
        background-position: ${getSpacing([12, 'md'])};
    `}

    ${getBreakpoint.print`
        background: none !important;
        padding: ${getSpacing(['sm', 'none', 'sm', 'sm'])};
    `}
`;

const InPageAlertStyle = css`
    ${props => props.variant === 'error' && `
        background-color: ${colors.backgrounds.error};
        border-color: ${colors.borders.error};
    `}
    ${props => props.variant === 'success' && `
        background-color: ${colors.backgrounds.success};
        border-color: ${colors.borders.success};
    `}
    ${props => props.variant === 'info' && `
        background-color: ${colors.backgrounds.info};
        border-color: ${colors.borders.info};
    `}
    ${props => props.variant === 'warning' && `
        background-color: ${colors.backgrounds.warning};
        border-color: ${colors.borders.warning};
    `}
    ${props => props.variant === 'callout' && `
        border-color: ${colors.borders.info};
    `}
`;

StyledInPageAlert.propTypes = {
    info: PropTypes.bool,
    error: PropTypes.bool,
    warning: PropTypes.bool,
    success: PropTypes.bool,
    plain: PropTypes.bool,
    className: PropTypes.string,
    variant: PropTypes.string
};

StyledInPageAlert.Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;

    ${getBreakpoint.sm`
        flex-flow: nowrap;
    `}

    ${props =>
        props.compact && css`
        flex-flow: nowrap;
    `};
`;

StyledInPageAlert.Title = styled.h6.attrs(props => ({
    as: (
        props.headingLevel === 'h2' ||
        props.headingLevel === 'h3' ||
        props.headingLevel === 'h4' ||
        props.headingLevel === 'h5' ||
        props.headingLevel === 'h6' ||
        props.headingLevel === 'p'
    ) && props.headingLevel
}))`
    font-size: ${pxToRem(font.size.base)};
    line-height: ${font.lineHeight.default};
    font-weight: ${font.weight.bold};
    margin: ${getSpacing(['none', 'none', 8])};
    max-width: 100%;

    &.title-only {
        margin-bottom: 0;
    }
`;

StyledInPageAlert.Icon = styled.div`
    padding: ${getSpacing(['none', 'sm', 'none', 'none'])};
    height: ${pxToRem(24)};

    svg {
        width: ${pxToRem(24)};
        height: ${pxToRem(24)};
    }
`;

StyledInPageAlert.Children = styled.div`
    ${getBreakpoint.sm`
        margin: ${getSpacing(['none', 'none', 'none', 40])};
    `}

    ${props => props.variant === 'callout' && css`
        ${getBreakpoint.sm`
            margin: ${getSpacing(['none', 'none', 'none', 'none'])};
        `}

        a {
            color: ${colors.text.link};
            svg {
                fill: ${colors.text.link};
            }
        }
    `}
`;
export default StyledInPageAlert;

StyledInPageAlert.Compact = styled.div`
   * {
        display: inline;
        &:not(:first-child) {
            margin-left: 7px;
        }
    }
`;