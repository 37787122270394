/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StyledInPageAlert from './InPageAlert.styled';
import { IconNotificationSuccess, IconNotificationError, IconNotificationWarning, IconNotificationInfo } from '../../Icons/system';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

export const InPageAlert = props => {
    const { variant, className, title, headingLevel, children, role, compact, innerRef, ...rest } = props;
    const iconArray = [
        ['error', <IconNotificationError size='md' />],
        ['warning', <IconNotificationWarning size='md' />],
        ['success', <IconNotificationSuccess size='md' />],
        ['info', <IconNotificationInfo size='md' />],
    ];
    const icons = new Map(iconArray);
    const displayIcon = icons.get(variant);

    useEffect(() => {
        trackMe('InPageAlert');
    }, []);

    return (
        <StyledInPageAlert
            variant={ variant }
            className={ className }
            role={ role }
            ref={ innerRef }
            { ...rest }
            compact={ compact }
        >
            <StyledInPageAlert.Wrapper compact={ compact }>
                <StyledInPageAlert.Icon>{displayIcon}</StyledInPageAlert.Icon>
                {compact ?
                    <StyledInPageAlert.Compact>
                        <StyledInPageAlert.Title headingLevel={ headingLevel }>{title}</StyledInPageAlert.Title>
                        {(compact && children) && <StyledInPageAlert.Children>{children}</StyledInPageAlert.Children>}
                    </StyledInPageAlert.Compact> :
                    <StyledInPageAlert.Title className={ classNames({ 'title-only': !children }) } headingLevel={ headingLevel }>{title}</StyledInPageAlert.Title>
                }
            </StyledInPageAlert.Wrapper>
            {(!compact && children) &&
                <StyledInPageAlert.Children>{children}</StyledInPageAlert.Children>
            }
        </StyledInPageAlert>
    );
};

InPageAlert.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['error', 'success', 'warning', 'info']).isRequired,
    role: PropTypes.oneOf(['alert', 'status']),
    children: PropTypes.node,
    headingLevel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf(['h2', 'h3', 'h4', 'h5', 'h6', 'p']),
    ]),
    compact: PropTypes.bool,
    innerRef: PropTypes.object,
};

InPageAlert.defaultProps = {
    headingLevel: 'h6',
    compact: false
};

export default InPageAlert;
