import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import InPageAlert from '../../Components/InPageAlert/InPageAlert';

const ErrorSummary = forwardRef(({ errors, id, singularTitle, pluralTitle }, ref) => {
    useEffect(() => {
        trackMe('ErrorSummary [GEL]');
    }, []);

    const isMoreThanOne = errors.length > 1;

    const list = errors.map(({ id, text }) => <li key={ id }><a href={ id.indexOf('#') === -1 ? `#${id}` : id }>{text}</a></li>);

    return (
        <>
            {errors.length > 0 &&
                <InPageAlert
                    title={ isMoreThanOne ? pluralTitle : singularTitle }
                    variant='error'
                    tabIndex='-1'
                    role='alert'
                    id={ id }
                    innerRef={ ref }
                >
                    <p>
                        {isMoreThanOne ?
                            `Please check the following ${errors.length} errors:` :
                            'Please check the following error:'
                        }
                    </p>
                    {isMoreThanOne ? <ol>{list}</ol> : <ul>{list}</ul>}
                </InPageAlert>
            }
        </>
    );
});

ErrorSummary.propTypes = {
    id: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
    })).isRequired,
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string,
};

ErrorSummary.defaultProps = {
    singularTitle: 'Your form has an error',
    pluralTitle: 'Your form has errors'
};

export default ErrorSummary;
