import styled from 'styled-components/macro';
import { genericButtonStyles, secondaryButtonStyle } from '../Button/Button.styled';
import tokens from '../../Tokens/tokens';

export const FileInputButton = styled.label`
    ${genericButtonStyles};
    ${secondaryButtonStyle};
    max-width: 200px;

    &:focus-within {
        outline: 2px solid ${tokens.colors.text.link};
        outline-offset: 2px;
    }
`;
