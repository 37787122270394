import breakpoints from './Tokens/compiled/breakpoints';
import spacing from './Tokens/compiled/spacing';
import font from './Tokens/compiled/font';
import { css } from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import countryList from 'country-list';
import dayjs from 'dayjs';

const DEFAULT_ID = 'id__';

let globalID;

if (globalID === undefined) {
    globalID = 0;
}

export const getID = (prefix = DEFAULT_ID) => {
    globalID++;
    return `${prefix}${globalID}`;
};

export const focusObjectGenerator = arr => {
    const focusableElements = {
        all: arr,
        first: arr[0],
        last: arr[arr.length - 1],
        length: arr.length,
    };

    return focusableElements;
};

export const getFocusableElement = el => {
    const elementArr = [].slice.call(el.querySelectorAll(`a[href],button:not([disabled]),
    area[href],input:not([disabled]):not([type=hidden]),
    select:not([disabled]),textarea:not([disabled]),
    iframe,object,embed,*:not(.is-draggabe)[tabindex],
    *[contenteditable]`));

    return focusObjectGenerator(elementArr);
};

export const trapTabKey = (event, focusObject) => {
    const { activeElement } = document;
    const focusableElement = focusObject;

    if (event.keyCode !== 9) {return false;};

    if (focusableElement.length === 1) {
        event.preventDefault();
    } else if (event.shiftKey && activeElement === focusableElement.first) {
        focusableElement.last.focus();
        event.preventDefault();
    } else if (!event.shiftKey && activeElement === focusableElement.last) {
        focusableElement.first.focus();
        event.preventDefault();
    }

    return true;
};

export const pxToRem = size => (`${size / 16}rem`);

export const pxToEm = size => (`${size / 16}em`);

export const getSpacing = sizes => {
    const sizesArr = (Array.isArray(sizes)) ? sizes : [sizes];
    const theSizes = sizesArr.map(size => {
        let returnVal;
        const spacingKeys = Object.keys(spacing);
        const tokenExists = spacingKeys.indexOf(size) !== -1;
        const isNumber = /^\d+$/.test(size);

        if (tokenExists) {
            // eslint-disable-next-line security/detect-object-injection
            const spaceToken = spacing[size];
            returnVal = pxToRem(spaceToken);
        }
        if (!tokenExists && isNumber) {
            returnVal = pxToRem(size);
        }

        if (!tokenExists && !isNumber) {
            const err = new Error('getSpacing function has been passed an invalid token.');
            throw err;
        };

        if (sizesArr.length > 4) {
            const err = new Error('getSpacing accepts arrays up to 4 values.');
            throw err;
        };

        return returnVal;
    });

    return theSizes.join(' ');
};

export const byteToMegabyte = byte => {
    const megabyte = (byte / (1024 * 1024)).toFixed(1);
    return megabyte;
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {return '0 Bytes';}

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[parseInt(i)]}`;
};

export class SyncPromise {
    constructor (resolved) {
        this.resolved = resolved;
    }
    then (callback) {
        const ret = callback(this.resolved);
        return new SyncPromise(ret);
    }
}

const mediaQuery = (...query) => (...rules) => css`@media ${css(...query)} { ${css(...rules)} }`;

export const getBreakpoint = {
    xs: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.xs)})`,
    sm: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.sm)})`,
    md: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.md)})`,
    lg: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.lg)})`,
    xl: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.xl)})`,
    print: mediaQuery`print`,
};

export const getTypeSize = (size, lineHeight = 'default') => {
    const lineHeightVal = (lineHeight === 'heading' ? font.lineHeight.heading : font.lineHeight.default);
    const typeSizeKeys = Object.keys(font.typeSize);
    const tokenExists = typeSizeKeys.indexOf(size) !== -1;
    if (tokenExists) {
        // eslint-disable-next-line security/detect-object-injection
        const fontSize = font.typeSize[size];
        const output = css`
            font-size: ${pxToRem(fontSize[0])};
            line-height: ${lineHeightVal};

            ${getBreakpoint.md`
                font-size: ${pxToRem(fontSize[1])};
            `}
        `;
        return output;
    }

};

export const marginMixin = ({ margin }) => {

    const output = css`margin-top: ${margin ? getSpacing(margin.top) : pxToRem(16)};`;

    return output;
};

// For testing if the code is running in a client-side browser, or it's being server-side rendered (SSR).
// Usage:
//      import { canUseDom } from '/utils';
//      if (canUseDom) { ... }
export const canUseDom = Boolean(typeof window !== 'undefined' && window.document && window.document.createElement);

export const useBoolean = initialValue => {
    const [value, setValue] = useState(initialValue);

    const setTrue = () => setValue(true);
    const setFalse = () => setValue(false);

    return [value, setTrue, setFalse];
};

export const Portal = ({ id, children }) => {
    const el = document.createElement('div');
    id && el.setAttribute('id', id);
    const elRef = useRef(el);
    useEffect(() => {
        const modalContainer = elRef.current;
        document.body.appendChild(modalContainer);
        return () => {
            if (modalContainer.parentElement) {
                modalContainer.parentElement.removeChild(modalContainer);
            }
        };
    }, []);
    return createPortal(children, elRef.current);
};

export const detectDragDrop = () => {
    var div = document.createElement('div');
    return ('draggable' in div) || ('ondragstart' in div && 'ondrop' in div);
};

// Get list of ISO 3166 countries
export const getCountries = () => countryList.getNames().sort();

export const getAustralianStates = () => (
    [
        'ACT',
        'NSW',
        'NT',
        'QLD',
        'SA',
        'TAS',
        'VIC',
        'WA'
    ]
);

/**
 * Used to recall the previous value of a prop or state.
 * Save this to a variable for future comparison in your component.
 * @param {*} value
 * @returns the current reference value.
 */
export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

/**
 * Used to check whether the component has rendered more than once.
 * @returns boolean; false on first render, true thereafter.
 */
export const useHasInitialised = () => {
    const [hasInitialised, setHasInitialised] = useState(false);
    useEffect(() => {
        setHasInitialised(true);
    }, []);
    return hasInitialised;
};

/**
 * Forces callback function to wait given time before being called
 * @param {*} func callback function to perform logic
 * @param {*} wait time in milliseconds to wait between calls
 * @returns callback function
 */
export const debounce = (func, wait) => {
    let timerId;
    return function (...args) {
        clearTimeout(timerId);

        timerId = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
};

/**
 * Checks to see if the component is still mounted, leveraging useRef().
 * Use for ensuring component mounted before performing state updates, etc...
 * @returns boolean
 */
export const useMountedState = () => {
    const mountedRef = useRef(false);
    useEffect(() => {
        mountedRef.current = true;
        return () => mountedRef.current = false;
    }, []);
    return mountedRef.current;
};

/**
 * Checks if object is empty.
 * @param {object} obj
 * @returns {boolean}
 */
export const isEmptyObj = obj => !obj || obj.constructor !== Object || Object.entries(obj).length === 0;

/**
 * Checks if array is empty.
 * @param {array} arr
 * @returns {boolean}
 */
export const isEmptyArr = arr => !arr || arr.constructor !== Array || arr.length === 0;

/**
 * Removes a property from an object.
 * @param {string} propKey the key you'd like to remove.
 * @param {object} object the object you'd like to remove the key/value pair from.
 * @returns {object} the new object sans any instances of the key.
 */
export const removeObjectProperty = (propKey, object) => {
    // extract the propKey property and remaining items separately
    const { [propKey]: propValue, ...rest } = object;
    // return only the remaining items
    return rest;
};

/**
 * helper function to format a date with DayJS from ISO format
 * @param {string} date
 * @param {string} format
 * @returns a formatted date
 */
export const getFormattedDate = (date, format) => dayjs(date).format(format);

/**
 * helper function to wrap event listeners with an element and handler function
 * @param {object} element
 * @param {string} eventName
 * @param {function} handler
 * @returns a formatted date
 */
export function useListener(element, eventName, handler) {
    useEffect(() => {
        const currentElement = 'current' in element ? element.current : element;
        if (!currentElement) {
            return;
        }
        currentElement.addEventListener(eventName, handler);
        return () => currentElement.removeEventListener(eventName, handler);
    }, [eventName, handler, element]);
};

/**
 * helper function to conditionally render aria-describedby ids
 * @param {string}
 * @returns a formatted aria-describedby string
 */
export const getAriaDescribedby = (...ids) => ids.filter(Boolean).join(' ') || null;

export const showDeprecatedMsg = (msg, link) => {
    console.warn(`${msg}\n\nMore info: ${link}`);
};

const utils = {
    getID,
    focusObjectGenerator,
    getFocusableElement,
    trapTabKey,
    pxToRem,
    pxToEm,
    getSpacing,
    getBreakpoint,
    getTypeSize,
    canUseDom,
    useBoolean,
    Portal,
    detectDragDrop,
    getCountries,
    getAustralianStates,
    byteToMegabyte,
    formatBytes,
    usePrevious,
    useHasInitialised,
    debounce,
    useMountedState,
    isEmptyObj,
    isEmptyArr,
    removeObjectProperty,
    getFormattedDate,
    useListener,
    getAriaDescribedby,
    showDeprecatedMsg
};

export default utils;
