import styled from 'styled-components/macro';
import { pxToRem } from '../../utils';
import { layout } from 'styled-system';
import { Input } from '../..';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

export const StyledManualAddress = styled.div`
    width: 100%;
`;

export const Flex = styled.div`
    display: flex;
    flex-grow: 1;

    div {
        &:first-child {
            max-width: ${pxToRem(106)};
            margin-right: ${pxToRem(8)};
        }
        &:last-child {
            margin-left: ${pxToRem(8)};
            max-width: ${pxToRem(92)};
        }
    }

    ${layout};
`;

export const ReadonlyInput = styled(Input)`
    opacity: 1;
    background-color: ${colors.backgrounds.disabled};
`;